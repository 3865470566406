<template>
	<div class="pay-success">

		<div class="top">
			<img src="@/assets/images/chenggong.png" class="pay-success-img">
			<p>积分兑换成功！</p>
		</div>

		<div class="box">
			<router-link tag="div" class="link red" to='/MallOrder'>查看积分订单</router-link>
			<router-link tag="div" class="link" to='/'>返回首页</router-link>
		</div>

	</div>

</template>
<style lang="scss" scoped>
	.pay-success {
		* {
			box-sizing: border-box;
		}

		min-height: 100vh;
		width: 100vw;
		background: #f6f6f6;

		.top {
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-bottom: .7rem;
			height: 62vw;

			img {
				width: 40vw;
				margin-bottom: .4rem;
			}

			p {
				font-size: .28rem;
			}
		}

		.box {
			display: flex;
			align-items: cener;
			justify-content: center;

			.link {
				margin: 0 .25rem;
				width: 32vw;
				height: .83rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #dcdcdc;
				font-size: .28rem;
				color: #8b8b8b;
			}

			.link.red {
				color: #e40011;
				border: 1px solid #e40011;
			}
		}
	}
</style>
<script>
	//import { FLAG_UNRESTRICTED } from 'html2canvas/dist/types/css/syntax/tokenizer';
	// import login from "@/common/islogin";
	// import Tabbar from "@/components/Tabbar";

	// import CustomerService from "@/views/home/children/CustomerService"; 

	export default {
		components: {},
		data() {
			return {
			};
		},
		mounted() {

		},
		methods: {

		},
		computed: {

		},
		filters: {

		}
	};
</script>
